import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentModal.figmaUrl.android} codeUrl={checklists.componentModal.codeUrl.android} checklists={checklists.componentModal.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Modal components in Android are pop-up windows that appear on top of an application to grab the user’s attention.Modal components are designed to demand attention, preventing users from interacting with the underlying application until they have addressed the modal by either dismissing it or completing the required action.`}</p>
    <undefined><div {...{
        "className": "line",
        "style": {
          "backgroundColor": "white"
        }
      }}>{`
  `}<div parentName="div" {...{
          "className": "item-one",
          "align": "center"
        }}>{`
    `}<div parentName="div" {...{
            "style": {
              "padding": "7px 0"
            }
          }}>{`
      `}<img parentName="div" {...{
              "align": "center",
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/modal/android-compose-modal.gif",
              "alt": "Legion Modal Android Compose"
            }}></img>{`
    `}</div>{`
  `}</div>
      </div>
      <div {...{
        "className": "divi"
      }}></div></undefined>
    <h2>{`Basic Example`}</h2>
    <h3>{`Basic Modal`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var showBottomSheet by remember { mutableStateOf(false) }

    LgnButton(
        onClick = {
            showBottomSheet = !showBottomSheet
        },
        label = "Open Modal",
        modifier = Modifier.fillMaxWidth()
    )

    LgnModal(
        visible = showBottomSheet,
        onDismissRequest = { showBottomSheet = false },
        title = "Modal Title",
        message = "Mauris, turpis augue mauris tellus massa, lacus. Id quam adipiscing tincidunt at feugiat arcu in laoreet id."
    )
`}</code></pre>
    <h3>{`Basic Modal Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`visible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`controls visibility in this modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`title which should specify the purpose of the dialog. The title is not mandatory`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text which presents the details regarding the dialog’s purpose`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismissRequest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Executes when the user tries to dismiss the dialog`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`properties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DialogProperties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DialogProperties`}</strong>{` for further customization of this dialog’s behavior`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<strong parentName="td">{`Modifier`}</strong>{` to be applied to the modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The shape of the bottom sheet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnModalColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LgnModalColors`}</strong>{` that will be used to resolve the colors used for this modal in different states. See `}{`[LgnModalDefaults.colors]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ImageVector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon to be displayed on the left side of header modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`showCloseIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`controls visibility of close icon in this modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`footer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The footer to be displayed inside the modal`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Decoration Example`}</h2>
    <p>{`By utilizing custom methods, you can dynamically modify icon, header, footer, and content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var showBottomSheet by remember { mutableStateOf(false) }

    LgnButton(
        onClick = {
            showBottomSheet = !showBottomSheet
        },
        label = "Open Modal",
        modifier = Modifier.fillMaxWidth()
    )

    LgnModal(
        visible = showBottomSheet,
        onDismissRequest = { showBottomSheet = false },
        header = {
            LgnModalDefaults.Header(
                title = { Text(text = "Modal Title") },
                showCloseIcon = true,
                onClose = { showBottomSheet = false },
                icon = { Icon(imageVector = Icons.Default.Face, contentDescription ="") }
            )
        },
        footer = {
            LgnButtonGroup(
                modifier = Modifier.padding(horizontal = 16.dp),
                firstTitleButton = "Yes",
                secondTitleButton = "No"
            )
        }
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .height(100.dp)
                .fillMaxWidth()
                .padding(
                    start = 16.dp,
                    end = 16.dp,
                )
                .border(
                    1.dp,
                    LegionTheme.colors.tertiary300,
                    LegionTheme.radius.radius7
                )
        ) {
            Text(text = "Content")
        }
    }
`}</code></pre>
    <h3>{`Decoration Modal Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`visible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`controls visibility in this modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismissRequest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Executes when the user tries to dismiss the dialog`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`properties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DialogProperties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DialogProperties`}</strong>{` for further customization of this dialog’s behavior`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<strong parentName="td">{`Modifier`}</strong>{` to be applied to the modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The shape of the bottom sheet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnModalColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LgnModalColors`}</strong>{` that will be used to resolve the colors used for this modal in different states. See `}{`[LgnModalDefaults.colors]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The header to be displayed inside the modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`footer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The footer to be displayed inside the modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content to be displayed inside the modal`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      